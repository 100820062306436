import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class ConvertService {

  unitsConvertions = [
    {
      "standard_naming": "depth",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "depth_to",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "formation_top_depth",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "formation_bottom_depth",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "total_depth",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "measured_depth",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "bit_depth",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "block_height",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "rop",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "rop_threshold",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "measured_depth_shift",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "depth_drilled",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "depth_in",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "depth_out",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "gamma_offset",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "formation_top_depth",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "formation_top_tvd",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "casing_point_depth",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "tvd",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "ns",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "ew",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "unwrap_departure",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "vs",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "gamma_depth",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "caliper",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "gamma_offset",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "sonic_offset",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "resistivity_offset",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "nphi_offset",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "rhob_offset",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "depth_drilled",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "wcr",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "min",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "max",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "avg",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "plan",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "depth_analysis_tvd_to",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "unwrap_departure_to",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "tvd_low",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "tvd_medium",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "tvd_extended",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "tvd_very_extended",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "tvd_to",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "unwrap_departure_to",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "measured_depth_best",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "measured_depth_worst",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "measured_depth_avg",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "bit_depth_best",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "bit_depth_worst",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "bit_depth_avg",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "drilling_rop",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "rotating_rop",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "sliding_rop",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "tripping_speed",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "tripping_in_speed",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "tripping_out_speed",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "rop_best",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "rop_worst",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "rop_avg",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "rop_median",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "measured_depth_middle",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "rotate_depth",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "rotate_rop",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "slide_depth",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "slide_rop",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "trip_in_depth",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "trip_in_speed",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "trip_out_depth",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "trip_out_speed",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "delta_bit_depth",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "pipe_speed",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "pipe_speed_worst",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "pipe_speed_best",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "pipe_speed_avg",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "measured_depth_min",
      "pluspetrol_conversion": "0,3048"
    },
    {
      "standard_naming": "measured_depth_max",
      "pluspetrol_conversion": "0,3048"
    }
  ];

  labels = [
    {
      "metric_unit": "psi",
      "pluspetrol_unit": "psi"
    },
    {
      "metric_unit": "FEET",
      "pluspetrol_unit": "METERS"
    },
    {
      "metric_unit": "ft/h",
      "pluspetrol_unit": "m/h"
    },
    {
      "metric_unit": "ft/hr",
      "pluspetrol_unit": "m/hr"
    },
    {
      "metric_unit": "ft",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "ft/day",
      "pluspetrol_unit": "m/day"
    },
    {
      "metric_unit": "days",
      "pluspetrol_unit": "days"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "decimal degrees",
      "pluspetrol_unit": "decimal degrees"
    },
    {
      "metric_unit": "decimal degrees",
      "pluspetrol_unit": "decimal degrees"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "m/s",
      "pluspetrol_unit": "m/hr"
    },
    {
      "metric_unit": "kN",
      "pluspetrol_unit": "klb"
    },
    {
      "metric_unit": "kN",
      "pluspetrol_unit": "klb"
    },
    {
      "metric_unit": "kN",
      "pluspetrol_unit": "klb"
    },
    {
      "metric_unit": "m^3/m",
      "pluspetrol_unit": "gal/min"
    },
    {
      "metric_unit": "percentage",
      "pluspetrol_unit": "percentage"
    },
    {
      "metric_unit": "m^3",
      "pluspetrol_unit": "bbl"
    },
    {
      "metric_unit": "pa",
      "pluspetrol_unit": "psi"
    },
    {
      "metric_unit": "pa",
      "pluspetrol_unit": "psi"
    },
    {
      "metric_unit": "pa",
      "pluspetrol_unit": "psi"
    },
    {
      "metric_unit": "kN-m",
      "pluspetrol_unit": "ftlb"
    },
    {
      "metric_unit": "kN-m",
      "pluspetrol_unit": "ftlb"
    },
    {
      "metric_unit": "rev/min",
      "pluspetrol_unit": "rev/min"
    },
    {
      "metric_unit": "kpa",
      "pluspetrol_unit": "kpsi"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "",
      "pluspetrol_unit": ""
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "in"
    },
    {
      "metric_unit": "(m^2)/32",
      "pluspetrol_unit": "(in^2)/32"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "kg/m^3",
      "pluspetrol_unit": "ppg"
    },
    {
      "metric_unit": "rev/m^3",
      "pluspetrol_unit": "rev/gal"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "degrees",
      "pluspetrol_unit": "degrees"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "in"
    },
    {
      "metric_unit": "deg",
      "pluspetrol_unit": "deg"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "deg/100m",
      "pluspetrol_unit": "deg/100ft"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "percentage",
      "pluspetrol_unit": "percentage"
    },
    {
      "metric_unit": "percentage",
      "pluspetrol_unit": "percentage"
    },
    {
      "metric_unit": "percentage",
      "pluspetrol_unit": "percentage"
    },
    {
      "metric_unit": "percentage",
      "pluspetrol_unit": "percentage"
    },
    {
      "metric_unit": "api",
      "pluspetrol_unit": "api"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "ohmm",
      "pluspetrol_unit": "ohmm"
    },
    {
      "metric_unit": "ohmm",
      "pluspetrol_unit": "ohmm"
    },
    {
      "metric_unit": "percentage",
      "pluspetrol_unit": "percentage"
    },
    {
      "metric_unit": "g/cc",
      "pluspetrol_unit": "g/cc"
    },
    {
      "metric_unit": "microseconds per foot",
      "pluspetrol_unit": "microseconds per foot"
    },
    {
      "metric_unit": "microseconds per foot",
      "pluspetrol_unit": "microseconds per foot"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "C",
      "pluspetrol_unit": "C"
    },
    {
      "metric_unit": "units",
      "pluspetrol_unit": "units"
    },
    {
      "metric_unit": "mg/L C",
      "pluspetrol_unit": "mg/L C"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "kg/m^3",
      "pluspetrol_unit": "ppg"
    },
    {
      "metric_unit": "kg/m^3",
      "pluspetrol_unit": "ppg"
    },
    {
      "metric_unit": "kg/m^3",
      "pluspetrol_unit": "ppg"
    },
    {
      "metric_unit": "kg/m^3",
      "pluspetrol_unit": "ppg"
    },
    {
      "metric_unit": "kg/m^3",
      "pluspetrol_unit": "ppg"
    },
    {
      "metric_unit": "kg/m^3",
      "pluspetrol_unit": "ppg"
    },
    {
      "metric_unit": "kg/m^3",
      "pluspetrol_unit": "ppg"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "m/day",
      "pluspetrol_unit": "m/day"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "m/s",
      "pluspetrol_unit": "m/hr"
    },
    {
      "metric_unit": "m/s",
      "pluspetrol_unit": "m/hr"
    },
    {
      "metric_unit": "m/s",
      "pluspetrol_unit": "m/hr"
    },
    {
      "metric_unit": "m/min",
      "pluspetrol_unit": "m/hr"
    },
    {
      "metric_unit": "m/min",
      "pluspetrol_unit": "m/hr"
    },
    {
      "metric_unit": "m/min",
      "pluspetrol_unit": "m/hr"
    },
    {
      "metric_unit": "m/s",
      "pluspetrol_unit": "m/hr"
    },
    {
      "metric_unit": "m/s",
      "pluspetrol_unit": "m/hr"
    },
    {
      "metric_unit": "m/s",
      "pluspetrol_unit": "m/hr"
    },
    {
      "metric_unit": "m/s",
      "pluspetrol_unit": "m/hr"
    },
    {
      "metric_unit": "kpa",
      "pluspetrol_unit": "kpsi"
    },
    {
      "metric_unit": "percentage",
      "pluspetrol_unit": "percentage"
    },
    {
      "metric_unit": "percentage",
      "pluspetrol_unit": "percentage"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "mm.dd",
      "pluspetrol_unit": "mm.dd"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "m/s",
      "pluspetrol_unit": "m/hr"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "m/s",
      "pluspetrol_unit": "m/hr"
    },
    {
      "metric_unit": "percentage",
      "pluspetrol_unit": "percentage"
    },
    {
      "metric_unit": "percentage",
      "pluspetrol_unit": "percentage"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "m/s",
      "pluspetrol_unit": "m/hr"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "m/s",
      "pluspetrol_unit": "m/hr"
    },
    {
      "metric_unit": "percentage",
      "pluspetrol_unit": "percentage"
    },
    {
      "metric_unit": "percentage",
      "pluspetrol_unit": "percentage"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "m/s",
      "pluspetrol_unit": "m/hr"
    },
    {
      "metric_unit": "m/s",
      "pluspetrol_unit": "m/hr"
    },
    {
      "metric_unit": "m/s",
      "pluspetrol_unit": "m/hr"
    },
    {
      "metric_unit": "m/s",
      "pluspetrol_unit": "m/hr"
    },
    {
      "metric_unit": "min",
      "pluspetrol_unit": "min"
    },
    {
      "metric_unit": "mm.dd",
      "pluspetrol_unit": "mm.dd"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "m",
      "pluspetrol_unit": "m"
    },
    {
      "metric_unit": "hr",
      "pluspetrol_unit": "hr"
    },
    {
      "metric_unit": "hr",
      "pluspetrol_unit": "hr"
    },
    {
      "metric_unit": "kpa",
      "pluspetrol_unit": "kpsi"
    },
    {
      "metric_unit": "pa",
      "pluspetrol_unit": "psi"
    },
    {
      "metric_unit": "years",
      "pluspetrol_unit": "years"
    },
    {
      "metric_unit": "stb/day",
      "pluspetrol_unit": "stb/day"
    },
    {
      "metric_unit": "mstb",
      "pluspetrol_unit": "mstb"
    },
    {
      "metric_unit": "stb/day",
      "pluspetrol_unit": "stb/day"
    },
    {
      "metric_unit": "mstb",
      "pluspetrol_unit": "mstb"
    },
    {
      "metric_unit": "",
      "pluspetrol_unit": ""
    },
    {
      "metric_unit": "s",
      "pluspetrol_unit": "days"
    },
    {
      "metric_unit": "MPa",
      "pluspetrol_unit": "psia"
    },
    {
      "metric_unit": "m^3/D",
      "pluspetrol_unit": "STB/D"
    },
    {
      "metric_unit": "m^3",
      "pluspetrol_unit": "STB"
    },
    {
      "metric_unit": "m^3/D",
      "pluspetrol_unit": "MMscf/D"
    },
    {
      "metric_unit": "m^3",
      "pluspetrol_unit": "MMscf"
    },
    {
      "metric_unit": "days^0.5",
      "pluspetrol_unit": "days^0.5"
    },
    {
      "metric_unit": "MPa/m^3/D",
      "pluspetrol_unit": "psi/STB/D"
    },
    {
      "metric_unit": "MPa/m^3/D",
      "pluspetrol_unit": "psi/MMscf/D"
    },
    {
      "metric_unit": "MPa/m^3/D",
      "pluspetrol_unit": "psi/STB/D"
    },
    {
      "metric_unit": "MPa/m^3/D",
      "pluspetrol_unit": "psi/MMscf/D"
    },
    {
      "metric_unit": "MPa",
      "pluspetrol_unit": "psia"
    },
    {
      "metric_unit": "MPa",
      "pluspetrol_unit": "psia"
    },
    {
      "metric_unit": "MPa",
      "pluspetrol_unit": "psia"
    },
    {
      "metric_unit": "",
      "pluspetrol_unit": ""
    },
    {
      "metric_unit": "s",
      "pluspetrol_unit": "days"
    },
    {
      "metric_unit": "MPa",
      "pluspetrol_unit": "psia"
    },
    {
      "metric_unit": "m^3/D",
      "pluspetrol_unit": "STB/D"
    },
    {
      "metric_unit": "m^3",
      "pluspetrol_unit": "STB"
    },
    {
      "metric_unit": "m^3/D",
      "pluspetrol_unit": "MMscf/D"
    },
    {
      "metric_unit": "m^3",
      "pluspetrol_unit": "MMscf"
    },
    {
      "metric_unit": "days^0.5",
      "pluspetrol_unit": "days^0.5"
    },
    {
      "metric_unit": "MPa/m^3/D",
      "pluspetrol_unit": "psi/STB/D"
    },
    {
      "metric_unit": "MPa/m^3/D",
      "pluspetrol_unit": "psi/MMscf/D"
    },
    {
      "metric_unit": "MPa/m^3/D",
      "pluspetrol_unit": "psi/STB/D"
    },
    {
      "metric_unit": "MPa/m^3/D",
      "pluspetrol_unit": "psi/MMscf/D"
    },
    {
      "metric_unit": "",
      "pluspetrol_unit": ""
    },
    {
      "metric_unit": "",
      "pluspetrol_unit": ""
    },
    {
      "metric_unit": "s",
      "pluspetrol_unit": "days"
    },
    {
      "metric_unit": "m^3/D",
      "pluspetrol_unit": "STB/D"
    },
    {
      "metric_unit": "m^3",
      "pluspetrol_unit": "STB"
    },
    {
      "metric_unit": "m^3/D",
      "pluspetrol_unit": "MMscf/D"
    },
    {
      "metric_unit": "m^3",
      "pluspetrol_unit": "MMscf"
    },
    {
      "metric_unit": "s",
      "pluspetrol_unit": "days"
    },
    {
      "metric_unit": "",
      "pluspetrol_unit": ""
    },
    {
      "metric_unit": "m^3/D",
      "pluspetrol_unit": "STB/D"
    },
    {
      "metric_unit": "m^3",
      "pluspetrol_unit": "STB"
    },
    {
      "metric_unit": "m^3",
      "pluspetrol_unit": "STB"
    },
    {
      "metric_unit": "m^3/D",
      "pluspetrol_unit": "MMscf/D"
    },
    {
      "metric_unit": "m^3",
      "pluspetrol_unit": "MMscf"
    },
    {
      "metric_unit": "m^3",
      "pluspetrol_unit": "MMscf"
    },
    {
      "metric_unit": "mm-dd-yyyy",
      "pluspetrol_unit": "mm-d+R2:R257d-yyyy"
    }
  ];

  constructor() { }

  convertUnitsSimple(data: any){    
    if(localStorage.getItem("unitSelected") == "pluspetrol"){
       let proccesData =  data.map((item: any) => {        
        item.data = item.data.map( (itemData: any) => {          
            itemData.y = parseFloat((itemData.y * 0.3048).toFixed(0));
            itemData.x = parseFloat((itemData.x * 0.3048).toFixed(0));  
            return itemData;
          });
          return item;
      })
      return proccesData;
    }else{
      return data;      
    }    
  }

  convertUnit(data: any, id: any, convert: boolean = true){    
    if(localStorage.getItem("unitSelected") == "pluspetrol"){
      let filter: any = this.unitsConvertions.filter((x: any) => x["standard_naming"] == id);
      if(filter.length > 0){
        let conversion: any = filter[0]["pluspetrol_conversion"]+"";
        conversion = parseFloat(conversion.replace(",","."));
        let resultado
        if(convert){
          resultado = parseFloat((conversion * parseFloat(data)).toFixed(0));
        } else {
          resultado = parseFloat((parseFloat(data) / conversion).toFixed(0));
        }
        return resultado;
      } else {
        return data;  
      }
    }else{
      return data;      
    }    
  }

  convertUnits(data: any, id: any = ""){
      if(id == "drilloptim-colors-prod/colors"){
        return data;
      } else {
        if(localStorage.getItem("unitSelected") == "pluspetrol"){
          let dataprocess = data;
          let isdata = false;
          if(data.hasOwnProperty("data")){  dataprocess = data.data; isdata = true;  }       
          if(Object.prototype.toString.call(dataprocess) === '[object Array]') {
            this.unitsConvertions.forEach((d: any)=>{
              dataprocess.map((m: any)=>{             
                  if(m.hasOwnProperty(d.standard_naming)){
                    let original = m[d.standard_naming];
                    let conversion: any = d.pluspetrol_conversion+"";
                    conversion = parseFloat(conversion.replace(",","."));
                    let resultado = parseFloat((conversion * parseFloat(m[d.standard_naming])).toFixed(4));
                    m[d.standard_naming] = resultado;
                    if(id == "drilloptim-stream-prod/streamingscroll" || id == "drilloptim-stream-prod/streamingdata"){
                      if(d.standard_naming == "depth"){
                        m["depth_base"] = original;
                      }
                    }
                  }
                });
            });
          }
          if(isdata){ return {data:dataprocess}; } else { return dataprocess; }
        } else {
          return data;
        }
      }
  }
  
  convertLabel(label: any){
    if(localStorage.getItem("unitSelected") == "pluspetrol"){
      let result = this.labels.filter((l)=>l.metric_unit == label);
      if(result.length>0){
        return result[0].pluspetrol_unit;
      } else {
        return label;
      }
    } else {
      return label;
    }
  }

}
