// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  keyEncrypt: ",%0<x98r?\\u8s_AjIjt2gZLFc71E0zUh",
  production: false,
  firebase: {
    apiKey: "AIzaSyAq1H3fsL7VIcKLi1CyREd6eWY6Vo_8_qc",
    authDomain: "drilling-optimization-enovate.firebaseapp.com",
    projectId: "drilling-optimization-enovate",
    storageBucket: "drilling-optimization-enovate.appspot.com",
    messagingSenderId: "534237850544",
    appId: "1:534237850544:web:e4af9dcb012a7aa16f4475",
    measurementId: "G-YN3PBF4TMJ"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
